import { mount } from "#js/components/utils"
import EasyMDE from "#dependencies/easymde"

globalThis.EasyMDE = EasyMDE

/**
 * Django inline admins use dynamic formsets to add new forms to the page.
 *
 * This event is fired when a new form is added to the page.
 * We need to re-initialize the markdown editors on the new form.
 * @type {HTMLElement} - The target of the event.
 */
document.addEventListener("formset:added", function (event) {
  const formset = event.target
  const textarea = formset.querySelector("textarea.markdown")
  if (textarea) {
    markdown(textarea)
  }
})

export default function markdown(element) {
  const wrapper = document.createElement("div")
  wrapper.classList.add("vLargeTextField")
  wrapper.style.display = "inline-block"
  wrapper.style.width = "624px" // 610px width + 2 * 6px padding + 2 * 1px border
  element.parentNode.append(wrapper)
  wrapper.appendChild(element)
  if (wrapper.parentNode.querySelector(".help")) {
    wrapper.parentNode.insertBefore(wrapper, wrapper.parentNode.querySelector(".help"))
  }

  return new globalThis.EasyMDE({
    element,
    // https://github.com/Ionaru/easy-markdown-editor#options-list
    hideIcons: ["italic", "image"],
    showIcons: ["unordered-list", "ordered-list", "table", "guide", "preview", "quote"],
    minHeight: "1em",
    spellChecker: false,
    inputStyle: "contenteditable",
    nativeSpellcheck: true,
  })
}

mount(markdown, "textarea.markdown:not([name*=__prefix__])")

const location = new URL(globalThis.location.href)
mount((element) => {
  const url = new URL(element.href, location)
  element.setAttribute("aria-current", "page")
  new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        element.setAttribute("aria-current", "location")
      } else {
        element.removeAttribute("aria-current", "page")
      }
      document.querySelectorAll(".nav--toc a").forEach(function (link) {
        link.classList.remove("underline")
      })
      document.querySelector(".nav--toc a[aria-current=location]")?.classList.add(
        "underline",
      )
    })
  }).observe(
    document.getElementById(url.hash.substring(1)),
  )
}, ".nav--toc a")
